import React from 'react'
import { Link } from 'gatsby'
import { TARGET_CLASS } from '../../utils/visible'

import './index.scss'

export const ThumbnailItem = ({ node }) => (
  <Link className={`thumbnail ${TARGET_CLASS}`} to={node.fields.slug}>
    <div key={node.fields.slug}>
      <h3>
        {node.frontmatter.title || node.fields.slug}<br />
        <span style={{ fontSize: '8pt', fontWeight: '400' }}> {node.frontmatter.date} - {node.fields.readingTime.text}</span>
      </h3>
      <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
    </div>
  </Link>
)
